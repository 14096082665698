<template>
  <div class="page-content">
    <div class="row chat-wrapper">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-5">
                <h5 class="mr-2">Group ({{ groupTotalRow || 0 }})</h5>
              </div>
              <div class="col-md-2">
                <h5 class="mr-2">Contact List: {{ activeGroup.name || '-' }} ({{ contactTotalRow || 0 }})</h5>
              </div>
              <div class="col-md-5">
                <el-button class="float-right btn-primary mr-2" size="small" @click="handlerClickAddBtn">
                  <font-awesome icon="plus"/>&nbsp; Add Contact
                </el-button>
                <el-button class="float-right btn-secondary mr-2" size="small" @click="handlerClickAddGroupBtn">
                  <font-awesome icon="plus"/>&nbsp; Add Group
                </el-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row position-relative">
              <div class="col-lg-5 chat-aside border-lg-right">
                <form class="search-form">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <form @submit.prevent="searchGroup">
                      <input v-model="groupSearch" type="text" class="form-control border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                    </form>
                  </div>
                </form>
                <div class="aside-content" v-loading="loading.group_list">
                  <div class="aside-body">
                    <div class="tab-content mt-1 ps_rail-y" style="min-height: 200px; height: auto; overflow-y: auto;">
                      <div class="tab-pane fade show active" id="groups" role="tabpanel" aria-labelledby="groups-tab">
                        <ul class="list-unstyled chat-list px-1">
                          <li v-for="(data) in groupList" :key="data.id" class="chat-item pr-1" @click="showDetailGroup(data)">
                            <a href="javascript:;" class="d-flex align-items-center">
                              <div class="d-flex align-items-center justify-content-between flex-grow border-bottom">
                                <div class="d-flex justify-content-between flex-grow">
                                  <p class="text-body">{{ data.name }}</p>
                                  <div class="d-flex">
                                    <button class="btn btn-outline-info btn-xs mr-1" @click="handlerClickAddGroupBtn(data)" title="Edit group">
                                      <font-awesome icon="edit"/>
                                    </button>
                                    <button class="btn btn-outline-danger btn-xs" @click="deleteGroup(data.id)" title="Delete group">
                                      <font-awesome icon="trash"/>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                        <div v-if="groupIsMoreResult" align="center">
                          <a href="#" @click="infiniteHandlerGroup">Load more</a>
                        </div>
                        <div v-if="!groupIsMoreResult && groupTotalRow != 0" align="center">
                          <p>No more groups</p>
                        </div>
                        <el-empty v-if="groupTotalRow == 0" description="No data"></el-empty>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 chat-content px-4">
                <form class="search-form">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <form @submit.prevent="searchContact">
                      <input v-model="contactSearch" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                    </form>
                  </div>
                </form>
                <div class="aside-content" v-loading="loading.contact_group">
                  <div class="aside-body">
                    <div class="tab-content mt-1 ps_rail-y" style="min-height: 200px; height: auto; overflow-y: auto; max-height: calc(100vh - 340px);">
                      <div class="tab-pane fade show active" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
                        <ul class="list-unstyled chat-list px-1">
                          <li v-for="(data) in contactList" :key="'contact-' + data.id" class="chat-item pr-1 py-1">
                            <div class="d-flex align-items-center">
                              <div :data-initials-contact="initials(data.contact.name)"/>
                              <div class="d-flex align-items-center justify-content-between flex-grow border-bottom">
                                <div>
                                  <p class="text-body">{{ data.contact.name }}</p>
                                  <div class="d-flex align-items-center">
                                    <p class="text-muted tx-13">({{ data.contact.phone_number }})</p>
                                  </div>
                                </div>
                                <div class="d-flex align-items-end text-body">
                                  <button class="btn btn-outline-danger btn-sm" @click="removeListContact(data)" title="Remove contact">
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div v-if="contactIsMoreResult && contactList && contactList.length < contactTotalRow" align="center">
                          <a href="#" @click="infiniteHandlerContact">Load more</a>
                        </div>
                        <div v-if="!contactIsMoreResult && contactTotalRow != 0" align="center">
                          <p>No more contacts</p>
                        </div>
                        <el-empty v-if="contactTotalRow == 0" description="No data"></el-empty>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="show_modal.single_add" title="Contact" hide-footer >
      <div class="aside-content">
        <div class="aside-body">
          <form class="search-form">
            <div class="input-group border rounded-sm">
              <div class="input-group-prepend">
                <div class="input-group-text border-0 rounded-sm">
                  <FeatherIcon type="search" class="icon-md cursor-pointer" />
                </div>
              </div>
              <form @submit.prevent="searchAllContact">
                <input v-model="allContactSearch" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </form>
            </div>
          </form>
        </div>
        <div class="aside-body">
          <div class="tab-content mt-1">
            <div class="tab-pane fade show active" id="contacts" role="tabpanel" aria-labelledby="contacts-tab" style="min-height: 200px">
              <ul class="list-unstyled chat-list px-1">
                <li v-for="(data) in allContactList" :key="data.id" class="chat-item pr-1 py-1" @click="selectToAddContact(data)">
                  <a href="javascript:;" class="d-flex align-items-center">
                    <div :data-initials-contact="initials(data.contact.name)"/>
                    <div class="d-flex align-items-center justify-content-between flex-grow border-bottom">
                      <div>
                        <p class="text-body">{{ data.contact.name }}</p>
                        <div class="d-flex align-items-center">
                          <p class="text-muted tx-13">({{ data.contact.phone_number }})</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <p v-for="(rowGroup) in data.contact.groups" :key="rowGroup.id" class="text-muted tx-13 mr-1">| {{ rowGroup.name }}</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <div v-if="allContactIsMoreResult && allContactList.length < allContactTotalRow" align="center">
                <a href="#" @click="infiniteHandlerAllContact">Load more</a>
              </div>
              <!-- <div v-if="!allContactIsMoreResult && allContactTotalRow != 0" align="center"> -->
              <div v-if="!allContactIsMoreResult && allContactList.length == allContactTotalRow" align="center">
                <p>No more contacts</p>
              </div>
              <el-empty v-if="allContactTotalRow == 0" description="No data"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="show_modal.group_form" :title="`${ groupForm.id ? 'Edit' : 'Add' } Group`" hide-footer v-loading="loading.group">
      <b-form @submit.prevent="groupForm.id ? updateGroup() : addGroup()">
        <b-form-group label="Name">
          <b-form-input id="i-user-name" type="text" v-model="groupForm.name" placeholder="Input group name" required />
        </b-form-group>

        <div class="d-flex flex-row mt-2 float-right">
          <!-- <b-button type="submit" @click="addGroup()" variant="primary" class="mr-2">{{ $t('general.submit') }}</b-button> -->
          <b-button type="submit" v-loading="loading.group" variant="primary" class="mr-2">{{ $t('general.submit') }}</b-button>
          <b-button type="button" @click="show_modal.group_form = !show_modal.group_form" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import groups from '../../api/groups';
import contacts from '../../api/contacts';

export default {
  name: 'ContactGroups',
  metaInfo: {
    title: 'Group Contacts',
  },
  data() {
    return {
      loading: {
        group: false,
        contact_group: false,
        group_list: false,
      },
      activeGroup: {},
      groupPage: 1,
      groupSearch: '',
      groupTotalRow: 0,
      groupIsMoreResult: true,
      groupList: [],
      contactPage: 1,
      contactSearch: '',
      contactTotalRow: 0,
      contactIsMoreResult: true,
      contactList: [],
      allContactPage: 1,
      allContactSearch: '',
      allContactTotalRow: 0,
      allContactIsMoreResult: true,
      allContactList: [],
      selectedGroupIds: [],
      selectedContactIds: [],
      show_modal: {
        single_add: false,
        group_form: false,
      },
      groupForm: {
        id: '',
        name: '',
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  async mounted() {
    await this.getContactGroup();
    if (this.groupList[0]) await this.showDetailGroup(this.groupList[0]);
  },
  methods: {
    async getContactGroup(is_reset = false) {
      this.loading.group_list = true;
      if (is_reset) {
        this.groupList = [];
        this.groupPage = 1;
        this.groupTotalRow = 0;
        this.groupIsMoreResult = true;
      }

      await groups.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.groupPage,
        search: this.groupSearch,
        search_by: 'name',
      })
        .then((response) => {
          this.groupList.push(...response.data.rows);
          this.groupTotalRow = response.data.count;
          if (this.groupList.length >= response.data.count) {
            this.groupIsMoreResult = false;
          }
        })
        .catch(() => {});
      this.loading.group_list = false;
    },
    async getContact(group_id) {
      this.loading.contact_group = true;
      await groups.getListContact({
        group_id,
        page: this.contactPage,
        search: this.contactSearch,
        search_by: 'name',
      })
        .then((response) => {
          this.contactList.push(...response.data.rows);
          this.contactTotalRow = response.data.count;
          if (this.contactList.length >= response.data.count) {
            this.contactIsMoreResult = false;
          }
        })
        .catch(() => {});
      this.loading.contact_group = false;
    },
    async getAllContact() {
      await contacts.getList({
        workspace_id: this.activeWorkspace._id,
        page: this.allContactPage,
        search: this.allContactSearch,
        search_by: 'name',
      })
        .then((response) => {
          this.allContactList.push(...response.data.rows);
          this.allContactTotalRow = response.data.count;
          if (this.allContactList.length >= response.data.count) {
            this.allContactIsMoreResult = false;
          }
        })
        .catch(() => {});
    },
    async showDetailGroup(group) {
      this.contactPage = 1;
      this.contactSearch = '';
      this.contactTotalRow = 0;
      this.contactIsMoreResult = true;
      this.contactList = [];
      this.activeGroup = group;
      await this.getContact(group.id);
    },
    async handlerClickAddGroupBtn(data = null) {
      this.show_modal.group_form = true;
      if (data !== null) {
        this.groupForm.id = data.id || '';
        this.groupForm.name = data.name || '';
      }
    },
    async addGroup() {
      this.loading.group = true;
      await groups.create({
        workspace_id: this.activeWorkspace._id,
        data: this.groupForm,
      })
        .then(() => {
          this.show_modal.group_form = false;
          this.groupForm.name = '';
          this.getContactGroup(true);
          this.$message({
            message: 'Successfully added group',
            type: 'success',
          });
        })
        .catch(() => {
          this.$message({
            message: 'Failed to add group',
            type: 'warning',
          });
        });
      this.loading.group = false;
    },
    async updateGroup() {
      this.loading.group = true;
      await groups.update({
        workspace_id: this.activeWorkspace._id,
        id: this.groupForm.id,
        // data: this.groupForm,
        data: {
          name: this.groupForm.name,
        },
      })
        .then(() => {
          this.show_modal.group_form = false;
          this.groupForm.name = '';
          this.getContactGroup(true);
          this.$message({
            message: 'Successfully updated group',
            type: 'success',
          });
        })
        .catch(() => {
          this.$message({
            message: 'Failed to update group',
            type: 'warning',
          });
        });
      this.loading.group = false;
    },
    async deleteGroup(id) {
      this.$confirm('Are you sure to delete this group?', 'Delete this group?', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
      }).then(async () => {
        this.loading.group = true;
        await groups.delete({
          workspace_id: this.activeWorkspace._id,
          id,
        }).then(async (resp) => {
          if (!resp.error) {
            await this.getContactGroup(true);
            if (id === this.activeGroup.id) {
              this.showDetailGroup(this.groupList[0]);
            }
            this.$message({
              type: 'success',
              message: 'Successfully delete group',
            });
          } else {
            this.$message({
              type: 'error',
              message: resp.error.message || 'Something wrong, please try again.',
            });
          }
        }).catch((err) => {
          this.$message({
            type: 'error',
            message: err.response.message || 'Something wrong, please try again.',
          });
        });
        this.loading.group = false;
      }).catch(() => {});
    },
    async handlerClickAddBtn() {
      if (this.activeGroup.id) {
        this.allContactList = [];
        this.allContactTotalRow = 0;
        this.allContactPage = 1;
        this.allContactIsMoreResult = true;
        await this.getAllContact();
        this.show_modal.single_add = true;
      }
    },
    async selectToAddContact(contact) {
      await groups.addListContact({
        groups_id: [
          this.activeGroup.id,
        ],
        contacts_id: [
          contact.contact.id,
        ],
      })
        .then(() => {
          this.showDetailGroup(this.activeGroup);
          // this.show_modal.single_add = false;
          this.$message({
            message: 'Successfully added contact',
            type: 'success',
          });
        })
        .catch(() => {
          this.$message({
            message: 'Failed to add contact',
            type: 'warning',
          });
        });
    },
    async removeListContact(contact) {
      this.$confirm('Are you sure to delete this contact in group?', 'Delete this contact?', {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
      }).then(async () => {
        await groups.removeListContact({
          groups_id: [
            this.activeGroup.id,
          ],
          contacts_id: [
            contact.contact.id,
          ],
        })
          .then(() => {
            this.showDetailGroup(this.activeGroup);
            this.$message({
              message: 'Successfully deleted contact',
              type: 'success',
            });
          })
          .catch(() => {
            this.$message({
              message: 'Failed to delete contact',
              type: 'warning',
            });
          });
      });
    },
    async searchGroup() {
      this.groupList = [];
      this.groupPage = 1;
      await this.getContactGroup();
    },
    async searchContact() {
      this.contactList = [];
      this.contactPage = 1;
      await this.getContact(this.activeGroup.id);
    },
    async searchAllContact() {
      this.allContactList = [];
      this.allContactPage = 1;
      await this.getAllContact();
    },
    async infiniteHandlerGroup() {
      this.groupPage += 1;
      await this.getContactGroup();
    },
    async infiniteHandlerContact() {
      this.contactPage += 1;
      await this.getContact(this.activeGroup.id);
    },
    async infiniteHandlerAllContact() {
      this.allContactPage += 1;
      await this.getAllContact();
    },
    initials(string) {
      return (string || '').split(' ').map((n) => n[0]).join('').slice(0, 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-wrapper .chat-aside .aside-body .tab-content {
  max-height: calc(100vh - 340px);
}
</style>
<style>
[data-initials-contact]:before {
  background: #8CC8ED;
  color: white;
  opacity: 1;
  content: attr(data-initials-contact);
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.dropify-wrapper {
  border-radius: 4px;
}
.dropify-wrapper .dropify-message p {
  font-size: 16px;
  color: #686868;
}
</style>
